<template>
  <v-container>
    <BaseTitlePage />

    <v-row>
      <v-col cols="12">
        <h1 class="report-titles">Selecione um relatório para prosseguir</h1>
      </v-col>
      <v-col cols="6" sm="2" v-for="(card, index) in cards" :key="index">
        <div class="card-report" @click="redirect(card)">
          <div class="card-report--icon primary--text">
            <i :class="card.icon"></i>
          </div>
          <div class="card-report--name">{{ card.name }}</div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    cards() {
      return [
        {
          name: 'Contratos',
          icon: 'fa-solid fa-file-contract',
          url: 'relatorio-contrato'
        },
        {
          name: 'Faturas',
          icon: 'fa-solid fa-receipt',
          url: 'relatorio-faturas'
        }
      ];
    }
  },

  methods: {
    redirect(card) {
      this.$router.push({ name: card.url });
    }
  }
};
</script>

<style lang="scss">
.report-titles {
  border-bottom: 1px solid;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 0.009375em;
  padding-bottom: 0.2rem;
}

.card-report {
  border-radius: 10px;
  border: 1px solid;
  cursor: pointer;
  padding: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.8rem;
  transition: background 0.5s 0s linear;

  &--name,
  &--title {
    font-weight: 400;
    letter-spacing: 0.0071428571em;
    text-align: center;
  }

  &--name {
    font-size: 1.2rem;
  }

  &--title {
    font-size: 0.875rem;
  }

  &--icon {
    align-items: center;
    align-self: center;
    display: inline-flex;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    height: 64px;
    width: 64px;
    transition-duration: 0.28s;
    transition-property: box-shadow, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    white-space: nowrap;

    &:before {
      background-color: currentColor;
      bottom: 0;
      border-radius: inherit;
      content: '';
      left: 0;
      opacity: 0.1;
      position: absolute;
      pointer-events: none;
      right: 0;
      top: 0;
      transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    }

    i {
      font-size: 1.8rem;
      color: var(--v-primary-base);
    }
  }
}

.biobe {
  &.theme--dark {
    .report-titles {
      color: $gray--20;
      border-color: $gray--60;
    }

    .card-report {
      border-color: rgba(156, 156, 156, 0.35);
      background-color: #323232;

      &:hover {
        background: #454545;
      }

      &--name {
        color: $gray--10;
      }

      &--title {
        color: $gray--20;
      }
    }
  }

  &.theme--light {
    .report-titles {
      color: $gray--40;
      border-color: rgba(33, 33, 33, 0.2);
    }

    .card-report {
      border-color: rgba(33, 33, 33, 0.2);
      background-color: #fff;

      &--name {
        color: $gray--40;
      }

      &--title {
        color: $gray--60;
      }
    }
  }
}
</style>
